// React
import React, { Component } from 'react';

// React 3rd Party
import { AiOutlineLine, AiOutlineMenu } from 'react-icons/ai';

// Other Components
import { Link } from 'react-router-dom';

function Icon(props) {
  const toggle = props.toggle;

  if (toggle) {
    return(<AiOutlineLine />);
  }
  
  return(<AiOutlineMenu />);
}

class Nav extends Component {
  state = {
    toggle: false,
  }

  _toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  }

  _onClick = () => {
    this.setState({ toggle: false });
  }

  render() { return(
    <div className='Nav'>
      <button className="desktop-hide" onClick={this._toggle}>
        <Icon toggle={this.state.toggle} />
      </button>
      <ul className={this.state.toggle ? 'links show' : 'links'}>
        <li><Link to='/' onClick={this._onClick}>Home</Link></li>
        <li><Link to='/about' onClick={this._onClick}>About</Link></li>
        <li><Link to='/showcase' onClick={this._onClick}>Showcase</Link></li>
        <li><Link to='/servers' onClick={this._onClick}>Game Servers</Link></li>
        <li><Link to='/forums' onClick={this._onClick}>Forums</Link></li>
      </ul>
    </div>
  ); }
}

export default Nav;

