// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollAnimation from 'react-animate-on-scroll';

// Other Components
import BigGear from './big-gear';

class Forum extends Component {
  render() { return(
    <div id='App-forum' className='App-container App-vending App-bg-fx App-bg-fx-matrix'>
      <div className='App-content'>
        <BigGear/>
        <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-forum'>
          <h1>The Knoob forums are currently being renovated...</h1>
          <p>In the mean time, please discuss away on <a href="https://discord.gg/nU3fer4">Discord!</a></p>
        </ScrollAnimation>
      </div>
    </div>
  ); }
}

export default Forum;

