// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollAnimation from 'react-animate-on-scroll';

// Resources
import logo from '../svg/gear.svg';

class BigGear extends Component {
  render() { return(
    <div className='App-big-gear'>
      <ScrollAnimation animateOnce={true} animateIn='fadeIn' duration={2} delay={500} scrollableParentSelector='.App'>
        <img src={logo} className='App-logo' alt='gear' />
      </ScrollAnimation>
    </div>
  ); }
}

export default BigGear;

