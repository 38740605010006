// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import ScrollAnimation from 'react-animate-on-scroll';

// Resources
import froogle from '../img/froogle.png';
import gitLab from '../svg/git_lab_logo.svg';

class Showcase extends Component {
  render() { return(
    <div id='App-showcase' className='App-page App-kyoto App-bg-fx App-bg-fx-matrix'>
      <div className='App-content'>
        <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-showcase'>
          <h1 className='App-neon red'><span className='App-flicker red'>The</span> Knoob Group Project Show<span className='App-flicker2 red'>ca</span>se</h1>
          <h2>Scroll to see projects</h2>
          <hr/>
          <h3>Froogle Home</h3>
          <img width='85%' height='auto' src={ froogle } alt='Froogle Home Screenshot'/>
          <p>Made for the Global Game Jam 2019.</p>
          <p>You can see it over at <a href='https://0xc0c0a.itch.io/froogle-home' target='_blank' rel='noopener noreferrer'>itch.io</a>.</p>
          <hr/>
          <h3>See some of our public GitLab projects</h3>
          <p><img width='128px' height='auto' src={ gitLab } alt='Git Lab logo'/></p>
          <a href='https://gitlab.com/knoob' target='_blank' rel='noopener noreferrer'>gitlab.com/knoob</a>
        </ScrollAnimation>
      </div>
    </div>
  ); }
}

export default Showcase;

