// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollAnimation from 'react-animate-on-scroll';

class GameServers extends Component {
  render() { return(
    <div id='App-gameServers' className='App-container App-vending App-bg-fx App-bg-fx-matrix'>
      <div className='App-content'>
        <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-gameServers'>
          <h1>Knoob Game Servers</h1>
          <h3>Minecraft Anarchy</h3>
          <p>A Minecraft survival server with no real rules, on survival mode with a hard difficulty.</p>
          <p>Good luck surviving...</p>
          <p><b>Server IP:</b> satelite.knoobgroup.com</p>
        </ScrollAnimation>
      </div>
    </div>
  ); }
}

export default GameServers;

