// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollAnimation from 'react-animate-on-scroll';

// Other Components
import BigGear from './big-gear';

class Forum extends Component {
  render() { return(
    <div id='App-404' className='App-container App-aesthetic App-bg-fx App-bg-fx-matrix'>
      <div className='App-content'>
        <BigGear/>
        <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-404'>
          <h1>404</h1>
          <h2>Tumbling Down</h2>
          <p>We couldn't find what you were looking for.</p>
          <p>It all returns to nothing.</p>
        </ScrollAnimation>
      </div>
    </div>
  ); }
}

export default Forum;

