// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollAnimation from 'react-animate-on-scroll';

// Resources
import equipment from '../img/knoob_equipment.png'

class About extends Component {
  render() { return(
    <div id='App-about' className='App-page App-aesthetic App-bg-fx App-bg-fx-matrix'>
      <div className="App-content">
        <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-about'>
          <h1>About Knoob Group</h1>
          <p>Knoob Group is a collection of friends who make things using computers!</p>
          <p>We started under the "Knoob" banner in 2011, but have been working on things in one form or another for even longer than that...</p>
          <p>Much of our community is made up from running game servers over several years, as well as various other projects we have going at any given time.</p>
          <h2>Please feel free to visit our <a href='/showcase'>showcase</a> for some of our work!</h2>
          <img width='85%' height='auto' src={ equipment } alt='Our repurposed ex Checkpoint firewall running pfsense'/>
        </ScrollAnimation>
      </div>
    </div>
  ); }
}

export default About;

