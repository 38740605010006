// React
import React, { Component } from 'react';

// Router
import { Route, Switch } from 'react-router-dom';

// Containers
import Home from './components/home';
import About from './components/about';
import Showcase from './components/showcase';
import GameServers from './components/game-servers';
import Forum from './components/forum';
import Nav from './components/nav';
import NotFound from './components/404';

// Resources
import './css/app.css';

class App extends Component {
  render() { return(
    <div className='App'>
      <Nav />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' component={About} />
        <Route path='/showcase' component={Showcase} />
        <Route path='/servers' component={GameServers} />
        <Route path='/forum' component={Forum} />
        <Route path='/forums' component={Forum} />
        <Route component={NotFound} status={404} />
      </Switch>
    </div>
  ); }
}

export default App;

