// React
import React, { Component } from 'react';

// React 3rd Party
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import ScrollAnimation from 'react-animate-on-scroll';

// Other Components
import BigGear from './big-gear';

configureAnchors({offset: -100})

class Home extends Component {
  render() { return(
    <div id='App-home'>
      <ScrollableAnchor id='title'>
        <div className='App-container App-kl App-bg-fx App-bg-fx-matrix'>
          <div className='App-content'>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn' duration={2} delay={200} scrollableParentSelector='#App-home'>
              <h1 className='App-neon'>
                Kn<span className='App-flicker2'>o</span>obGr<span className='App-flicker'>ou</span>p
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-home'>
              <h3>
                Software Development · Community Game Server Management · Game Design
              </h3>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id='under-construction'>
        <div className='App-container App-aesthetic App-bg-fx App-bg-fx-grid'>
          <div className='App-content'>
            <BigGear/>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn' scrollableParentSelector='#App-home'>
              <h1>Our site is currently under contruction</h1>
              <h2>Expect us back shortly!</h2>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id='nothing-here'>
        <div className='App-container App-kyoto App-bg-fx App-bg-fx-matrix'>
          <div className='App-content'>
            <ScrollAnimation animateOnce={true} animateIn='fadeInUp' duration={2} scrollableParentSelector='#App-home' initiallyVisible={true}>
              <h1>Seriously, there is nothing here yet!</h1>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id='why-though'>
        <div className='App-container App-vending App-bg-fx App-bg-fx-grid'>
          <div className='App-content'>
            <h1>
              <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={1500} scrollableParentSelector='#App-home'>
                <span>.</span>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={2000} scrollableParentSelector='#App-home'>
                <span>.</span>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={2500} scrollableParentSelector='#App-home'>
                <span>.</span>
              </ScrollAnimation>
            </h1>
          </div>
        </div>
      </ScrollableAnchor>
    </div>
  ); }
}

export default Home;

